/* 
green: #3FAF82

*/

.box {
    border-radius: 50%;
    position: relative;
    width: var(--font-size-800);
    height: var(--font-size-800);
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &:focus {
        outline: none;
    }
}

.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--font-size-800);
    cursor: pointer;

    span {
        display: block;
        width: 100%;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        height: 2px;
        background: var(--color-text-300);
        transition: all .3s ease;
        position: relative;
    }

    span+span {
        margin-top: 5px;
    }
    
}


.active {
    span:nth-child(1) {
        animation: ease .7s top-1;
        top: calc(var(--font-size-800) / 4);
        transform: rotate(45deg);
    }

    span:nth-child(2) {
        animation: ease .7s scaled;
        transform: scale(0);
    }

    span:nth-child(3) {
        animation: ease .7s bottom-1;
        bottom: calc(var(--font-size-800) / 4);
        transform: rotate(135deg);
    }

    // Fix of ghost hover for touch devices, disable hover effect
    @media (hover: hover) {
        &:hover {
            span:nth-child(1) {
                transform: rotate(30deg);
            }
        
            span:nth-child(3) {
                transform: rotate(150deg);
            }
        }
    }
}


.inactive {
    span:nth-child(1) {
        animation: ease .7s top-2;
        top: 0;
        transform: rotate(0deg);
    }

    span:nth-child(2) {
        animation: ease .7s scaled-2;
        transform: scale(1);
    }

    span:nth-child(3) {
        animation: ease .7s bottom-2;
        bottom: 0;
        transform: rotate(0);
        
    }

       // Fix of ghost hover for touch devices, disable hover effect
       @media (hover: hover) {
            &:hover {
                span:nth-child(1) {
                    top: -3px;
                }
            
                span:nth-child(3) {
                    bottom: -3px;
                }
            }
        }
}


@keyframes top-1 {
    0% {
        top: 0;
        transform: rotate(0);
    }

    50% {
        top: calc(var(--font-size-800) / 4);
        transform: rotate(0);
    }

    100% {
        top: calc(var(--font-size-800) / 4);
        transform: rotate(45deg);
    }
}

@keyframes top-2 {
    0% {
        top: calc(var(--font-size-800) / 4);
        transform: rotate(45deg);
    }

    50% {
        top: calc(var(--font-size-800) / 4);
        transform: rotate(0deg);
    }

    100% {
        top: 0;
        transform: rotate(0deg);
    }
}

@keyframes bottom-1 {
    0% {
        bottom: 0;
        transform: rotate(0);
    }

    50% {
        bottom: calc(var(--font-size-800) / 4);
        transform: rotate(0);
    }

    100% {
        bottom: calc(var(--font-size-800) / 4);
        transform: rotate(135deg);
    }
}

@keyframes bottom-2 {
    0% {
        bottom: calc(var(--font-size-800) / 4);
        transform: rotate(135deg);
    }

    50% {
        bottom: calc(var(--font-size-800) / 4);
        transform: rotate(0);
    }

    100% {
        bottom: 0;
        transform: rotate(0);
    }
}

@keyframes scaled {
    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes scaled-2 {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}